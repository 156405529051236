import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

type propSpinner = {
    text: string
}

const Spinner = (props: propSpinner) => {

    return (
        <div className="text-center">
            <div style={{height:60,width:60, margin:'0px auto'}}>
                <img src={require('../../assets/ball-triangle.svg').default} width="70px" alt=""></img>
            </div>
            <br></br>
            <p style={{ fontSize:16, color:'#c99900', paddingTop:10}}>
                <FormattedHTMLMessage id={props.text} defaultMessage="Loading" />
            </p>
        </div>
    );
}

export default Spinner;