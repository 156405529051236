import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import datosReserva from "../../interfaces/datosReserva";
import PaymentSummary from "../PaymenSummary/PaymentSummary";
import PaymentDetails from "../PaymentDetails/PaymentDetails";
import { FormattedHTMLMessage } from "react-intl";
import moment from "moment";
import './ManagementPaymentMethods.css'
import PayManagement from "../Pago/PayManagement";
import Aplazame from "../FinantialCompany/Aplazame";
import Spinner from "../Spinner/Spinner";
import Condiciones from "../Condiciones/Condiciones";
import WarningCurrency from "../Condiciones/WarningCurrency";
import PagoResult from "../Pago/PagoResult";
import BankTransfer from "../BankTransfer/BankTransfer";

type PropsManagementPaymentMethods = {
    datosReserva: datosReserva,
    setShowError: Dispatch<SetStateAction<any>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setShowPago: Dispatch<SetStateAction<boolean>>,
    setSecondTry: Dispatch<any>,
    tryNumber: any,
  }

const ManagementPaymentMethods= (props: PropsManagementPaymentMethods) => {
  
  const {pendiente, pagado, pvp, currency} = props.datosReserva.data.reserva

  const showSelect = (pvp - pagado) - pendiente > 0 ? true : false

  const [selectAmountToPay, SetSelectAmountToPay] = useState<any>({payAllAmount: false, showSelect: showSelect, showPaymentMethods: !showSelect});

  const [showResultOK, setShowResultOK] = useState<boolean>(false);

  const [showResultKO, setShowResultKO] = useState<boolean>(false);

  const [disablePaymenMethods, setDisablePaymenMethods]  = useState<boolean>(true);

  const [showPayment, setShowPayment] = useState<any>({TPVAlreadyLoaded : false, paymentLoading: false});
  
  const [showTransfer, setShowTransfer] = useState<any>({transferAlreadyLoaded : false, transferLoading: false});
  
  const {datosReserva, setLoading, setShowPago, setShowError, setSecondTry, tryNumber} = props

  const [ DataRule, setDataRule] = useState({ hasData: false, TPVName: '', showTPV: false, showFinantial: false, showTransferBank: false})

  const { hasData, TPVName } = DataRule
  
  const [numberClicks, setNumberClicks] = useState(1)

  const [showMessageResultEnd, setShowMessageResultEnd] = useState<boolean>(false);

  useEffect(() => {

    fetchGetDataFromBusinessRule()

  },[])

  const fetchGetDataFromBusinessRule = async () => {

    const response = await fetch(`${process.env.URL_API_ENDPOINT}/api/TPV/GetDataFromBusinessRule`, {
        method: 'POST',
        body: JSON.stringify({HotelId: props.datosReserva.data.hotel.dphote, Currency: props.datosReserva.data.reserva.currency}), 
        headers: {'Content-Type': 'application/json'}
      })
    
    let { hasData, TPVName, showTPV, showFinantial, showTransferBank, IPAddress} = await response.json() 

    setDataRule({ hasData, TPVName, showTPV, showFinantial, showTransferBank })
    setLoading(false)  
    setShowPago(true) 
    
  }

  const handlerSelectAmountToPay = (payAllAmount: boolean) => {
    SetSelectAmountToPay({payAllAmount: payAllAmount, showSelect: selectAmountToPay.showSelect, showPaymentMethods: true})
  }

  const handlerChangePaymentMethods = (method: string) => {

    const isAplazameShown = datosReserva.data.reserva.pendiente <= Number(process.env.MAX_AMOUNT_APLAZAME_EUR) && (props.datosReserva.data.reserva.customeremail && props.datosReserva.data.reserva.customeremail.length > 0)

    let containerPayManagement: any = document.getElementById('containerPayManagement')
    let containerAplazame: any
    let containerBankTransfer : any = document.getElementById('containerBankTransfer')

    let iconBankTransfer : any = document.getElementById('iconBankTransfer')

    iconBankTransfer.style.display = 'none'

    if (selectAmountToPay.showSelect) {
      SetSelectAmountToPay({...selectAmountToPay, showSelect: false})
    }

    if (isAplazameShown){
      containerAplazame = document.getElementById('containerAplazame')
    }
    
    if (method === 'TPV') {

      containerPayManagement.style.display = 'block'

      if (isAplazameShown){
        containerAplazame.style.display = 'none'
      }

      containerBankTransfer.style.display = 'none'

      if (!showPayment.TPVAlreadyLoaded){
        setShowPayment({...showPayment, paymentLoading: true})
      }

    }
    else if(method === 'Finantial'){

      containerPayManagement.style.display = 'none'

      if (isAplazameShown){
        containerAplazame.style.display = 'block'
      }
      containerBankTransfer.style.display = 'none'

    }
    else if(method === 'BankTransfer'){
      setNumberClicks(numberClicks + 1)
      containerBankTransfer.style.display = 'block'     

      containerPayManagement.style.display = 'none'
      if (isAplazameShown){
        containerAplazame.style.display = 'none'
      }

      if (!showTransfer.transferAlreadyLoaded){
        setShowTransfer({...showTransfer, transferLoading: true})
      }
    }
  }

  const handlerShowPago = (value: boolean) => {

    if (!showPayment.TPVAlreadyLoaded){
      setShowPayment({TPVAlreadyLoaded: true, paymentLoading: false})
    }

    setShowPago(value)
  }
  
  const handlerTransferLoaded = (loaded: any) => {    

    if (loaded) {
      let iconBankTransfer : any = document.getElementById('iconBankTransfer')

      iconBankTransfer.style.display = 'inline-block'
    }
    
  }

  const handlerSetLoading = (value:any) => {
    //console.log('handlerSetLoading:', value)
  }

  const handlerSetAplazameLoading = (value:any) => {

    let divLoading: any = document.getElementById('divLoading')

    const displayValue = value ? 'block' : 'none'

    divLoading.style.display = displayValue

  }

  const handlerShowWarningCurrency = (value:any) => {

    let containerWarningCurrency: any = document.getElementById('containerWarningCurrency')
    containerWarningCurrency.style.display = value ? 'block' : 'none'

  }

  const handlerShowResponse = (data: any) => {
    
    if (data.showResult){
      data.showResult && data.message == 'OK' ? setShowResultOK(true) : setShowResultKO(true)
      handlerShowWarningCurrency(false)
    }

  }
  
  const handleOnMouseEnterLeaveInfo = (typeMove) => {   

    let imgIconCopy: any =  document.getElementById('imgIconInforTransfer')
    imgIconCopy.src = typeMove === 'enter' ? require('../../assets/icono-infoGray.svg').default : require('../../assets/icono-infoBlue.svg').default      
  }

  const showMessageResult = ( componentName => {

    if (componentName === 'BankTransfer' ) {
      setShowMessageResultEnd(true)
    }
  }) 

  const handleOnMouseEnterLeaveEmail = (typeMove) => {    
        
    let imgIconCopy: any = document.getElementById('imgIconCopyEmail')

    imgIconCopy.src = typeMove === 'enter' ? require('../../assets/icono-copiar.svg').default : require('../../assets/icono-copiar-blue.svg').default      
  }

  const handleChangeCheckAcceptTerms = () => {
    setDisablePaymenMethods(!disablePaymenMethods)
  }

  return (

    <React.Fragment>
      
      { hasData && !showMessageResultEnd &&
        <React.Fragment>

          <div id="containerWarningCurrency" style={{display:'none'}}>
            <WarningCurrency/>
          </div>
          
          {!showResultOK && !showResultKO && datosReserva.data && datosReserva.data.reserva.depgdo == 'N' &&
            <div id="Condiciones" style={{marginBottom:'20px'}}>

                <input type="checkbox" className='checkAcceptTerm' checked={!disablePaymenMethods} onChange={ handleChangeCheckAcceptTerms}/>

                <div className='divTerms'>

                <Condiciones 
                  data={{
                  fecha: datosReserva.data.reserva.deftop,
                  restricciones: datosReserva.data.reserva.restricciones,
                  cancelacion: datosReserva.data.reserva.cancelacion,
                  showWarningCurrency: false,
                  hotel: datosReserva.data.reserva.eN_HOTEL,
                  datosReserva: datosReserva
                }} 
              />

                </div>

            </div>
          }

          {
              !showResultOK && !showResultKO && selectAmountToPay.showSelect &&
              (
                <div className={'selectRadioButton'}>

                  <input type="radio" id="radioAmountPendiente" name='radioSelectAmountToPay' value = 'false' onChange={() => handlerSelectAmountToPay(false)} />
                  <span className='PaymentMethodsRadioLabel'><FormattedHTMLMessage id="app.selectAmountToPayPending" defaultMessage="Pagar cantidad pendiente." /></span>

                  <br/>

                  <input type="radio" id="radioAmountAll" name='radioSelectAmountToPay' value = 'true' onChange={() => handlerSelectAmountToPay(true)} />
                  <span className='PaymentMethodsRadioLabel'><FormattedHTMLMessage id="app.selectAmountToPayAll" defaultMessage="Pagar cantidad total." /></span>

                </div>
              )
          }

          {!showResultOK && !showResultKO && selectAmountToPay.showPaymentMethods &&
            <div className={disablePaymenMethods ? 'selectRadioButton divDisabled' : 'selectRadioButton'}>

              <span className='PaymentMethodsTitle'>
                <FormattedHTMLMessage id="app.paymentMethod_Selection" defaultMessage="Elegir método de pago" />
              </span>

              <br/>

              <div className='PaymentMethodOptions'>

                <input type="radio" id="radioTPV" name='radioMethods' value = 'TPV' onChange={() => handlerChangePaymentMethods('TPV')} />
                <span className='PaymentMethodsRadioLabel'><FormattedHTMLMessage id="app.paymentMethod_TPV" defaultMessage="Tarjeta de crédito o débito" /></span>

                {showPayment.paymentLoading &&
                    
                    <Spinner text='app.method_loading'/>
                }
                
                <div id="containerPayManagement" style={{display:'none'}}>

                  {(showPayment.TPVAlreadyLoaded || showPayment.paymentLoading) && 

                    <PayManagement datosReserva={datosReserva}
                        setShowError={setShowError}
                        setLoading={handlerSetLoading}
                        setShowPago={handlerShowPago}
                        setSecondTry = {setSecondTry}
                        setShowWarningCurrency = {handlerShowWarningCurrency}
                        setShowResponse = {handlerShowResponse}
                        payAllAmount = {selectAmountToPay.payAllAmount}
                        tryNumber = {tryNumber}
                        TPVName = {TPVName}

                    />
                  }

                </div>

                <br/>

                {  datosReserva.data.reserva.pendiente <= Number(process.env.MAX_AMOUNT_APLAZAME_EUR) && (props.datosReserva.data.reserva.customeremail && props.datosReserva.data.reserva.customeremail.length > 0)  &&

                  <React.Fragment>

                    <input type="radio" id="radioFinantial" name='radioMethods' value = 'Finantial' onChange={() => handlerChangePaymentMethods('Finantial')}/>
                    <span className='PaymentMethodsRadioLabel'><FormattedHTMLMessage id="app.paymentMethod_Finantial" defaultMessage="Financiación a plazos" /></span>

                    <div id="divLoading" style={{display:'none'}}>
                    </div>
    
                    <div id="containerAplazame" style={{display:'none'}}>
    
                      <Aplazame 
                        datosReserva={datosReserva}
                        setShowError={setShowError}
                        setLoading={handlerSetAplazameLoading}                        
                        payAllAmount = {selectAmountToPay.payAllAmount}
                      />
    
                    </div>

                  </React.Fragment>
                }

                <br/>

                <input type="radio" id="radioBankTransfer" name='radioMethods' value = 'BankTransfer' onChange={() => handlerChangePaymentMethods('BankTransfer')} />
                <span className='PaymentMethodsRadioLabel'><FormattedHTMLMessage id="app.paymentMethod_BankTransfer" defaultMessage="Transferencia bancaria" />                
                
                {
                  <div id="iconBankTransfer" className= "AccountData" style = {{display:'none'}}> 

                  <a href="#" onClick={ () => handlerChangePaymentMethods('BankTransfer')}>
                      <img id='imgIconInforTransfer' style={{ paddingLeft: 10}} src={require('../../assets/icono-infoBlue.svg').default } 
                      onMouseEnter = { () => {handleOnMouseEnterLeaveInfo('enter')}}
                      onMouseLeave = { () => {handleOnMouseEnterLeaveInfo('leave')}}></img>
                  </a>
                </div>  
                
                }                
                  <div id="containerBankTransfer" style={{display:'none'}}> 
                  {showTransfer.transferAlreadyLoaded  || showTransfer.transferLoading && (
                          <BankTransfer datosReserva={datosReserva}
                              setShowError={ setShowError } 
                              setLoaded={ handlerTransferLoaded }
                              showMessageResult = { showMessageResult }
                              numberClicks = {numberClicks}                       
                          />  
                  )}          
                  </div> 
                </span>

              </div>

            </div>
          }

          {!showResultOK && !showResultKO &&
            <PaymentSummary datosReserva= {datosReserva} payAllAmount = {selectAmountToPay.payAllAmount}/>
          }

          <br/>

          {!showResultOK && !showResultKO &&
            <PaymentDetails datosReserva= {datosReserva}/>
          }

          {(showResultOK || showResultKO) &&
            <PagoResult 
              datosReserva={datosReserva}
              typeResult = { showResultOK ? true : false }
              tryNumber = {Number(tryNumber) + 1}
              setSecondTry = {setSecondTry}
            />
          }
          
        </React.Fragment>
      }

      { showMessageResultEnd &&

          <div id="ContainerResult">
            <div style ={{marginBottom: 15}}>
                <FormattedHTMLMessage id="app.bankTransferInformationLine1" defaultMessage="Muchas gracias!" />  
            </div>
            <div style ={{marginBottom: 15}}>
                <FormattedHTMLMessage id="app.bankTransferInformationLine2" defaultMessage="Quedamos a la espera de recibir su transferencia bancaria." />
            </div> 
        
            <div>
                <FormattedHTMLMessage id="app.bankTransferInformationLine3" defaultMessage="Le agradecemos, por favor, la realice lo antes posible para que podamos hacer efectiva su reserva y nos" />  
            </div>

            <p style={{paddingTop:'10px', paddingBottom:'20px'}}>                            
                <img style={{ paddingRight: 10 }} src={require('../../assets/icono-email.svg').default}></img>                            
                <a style={{color:'rgba(0,29,48,1)', fontSize: 20}} href="mailto: reservas@senatorhr.com">
                    <FormattedHTMLMessage id="app.email_contacto" defaultMessage="reservas@senatorhr.com" />
                </a>
                <a href="#" >
                    <img id='imgIconCopyEmail' style={{ paddingLeft: 6, paddingRight: 13 }} src={require('../../assets/icono-copiar-blue.svg').default}
                    onMouseEnter = { () => {handleOnMouseEnterLeaveEmail('enter')}}
                    onMouseLeave = { () => {handleOnMouseEnterLeaveEmail('leave')}}>
                    </img>
                </a>
            </p>

            <div id="Buttons">
        
              <a href={process.env.URL_RETURN_WEB}>
                  <FormattedHTMLMessage id="app.ir_sitio_web" defaultMessage="Ir al sitio web" />
              </a>
              
              <a href="#" id="otherPayment" onClick={() => setSecondTry({ 
                                                                              localizador: datosReserva.localizador, 
                                                                              fecha:moment.utc(datosReserva.data.reserva.dpentr, 'YYYYMMDD').format("DD/MM/YYYY"),
                                                                              tryNumber: tryNumber,
                                                                              paymentWasOK : true
                                                                          })
                                                      }>
                                                          
                  <FormattedHTMLMessage id="app.realizar_otro_pago" defaultMessage="Realizar otro pago" />
              </a>

            </div>

          </div>  
      }

    </React.Fragment>

  )
}

export default ManagementPaymentMethods;