import React from 'react';
import { useIntl, IntlShape } from 'react-intl';


type PropsShowError = {
    code: number
}

const ShowError = (props: PropsShowError) => {

    const intl: IntlShape = useIntl();

    const backGroundColor = props.code == 9111 ? '#F6FFED' : '#FFEEEE';
    const border = props.code == 9111 ? '1px solid #B7EB8F' : '1px solid #FC2020';
    const styleParent = {border:border, borderRadius:'5px', backgroundColor:backGroundColor, minHeight: '45px', margin: '0 auto', paddingBottom: 10};
    const iconRequire = props.code == 9111 ? require('../assets/icono-check-small.svg').default  : require('../assets/icono-info-error.svg').default ;

    return (

        <div className="container" >
            <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                <div style={styleParent}>
                    <div style = {{float:'left', minHeight:'45px'}}>
                        <img style={{ padding: '12px' }} src={iconRequire}></img>
                    </div>
                    <div style={{textAlign:'left', paddingTop: '8px'}}>
                        <p style={{display:'inline', }}>
                            { intl.messages['app.error.' + props.code] }
                        </p>
                    </div>
                </div>  
                </div>
            </div>
        </div>

                          
    );
}

export default ShowError;