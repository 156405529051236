import React from "react";

import '../styles/AppStyles.css';

import { GetKOIcon } from "../utilities/Icons";
import { FormattedHTMLMessage } from "react-intl";

const KOPage = () : JSX.Element => {

    const sendMessage = () : void => {
        window.parent.postMessage("go_home","*");
    }

    return(
        <div className="ko-page">
            {GetKOIcon()}

            <p className="message ko">
                <FormattedHTMLMessage id="ko.message" defaultMessage="Error en el pago"/>
            </p>
            <br/>
            <button onClick={sendMessage} className="btn-go-home btn-continuar">
                <FormattedHTMLMessage id="btn.go-home" defaultMessage="Volver al inicio"/>
            </button>
        </div>
    )
}

export default KOPage;