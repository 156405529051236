import React, { useEffect, Dispatch, SetStateAction, useState } from "react";
import Condiciones from '../Condiciones/Condiciones';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import NumberFormat from 'react-number-format';
import { FormattedHTMLMessage } from "react-intl";
import datosReserva from "../../interfaces/datosReserva";
import { LangContext } from "../Context/Context";
import moment, { lang } from "moment";
import IdatosTpvFormulario from "../../interfaces/datosTpvFormulario";
import { PaymentView } from "../Iframe/Iframe";
import { useForm } from "react-hook-form";
import './Pago.css'
import PaymentSummary from "../PaymenSummary/PaymentSummary";

type PropsPago = {
  datosReserva: datosReserva,
  setShowError: Dispatch<SetStateAction<any>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setShowPago: Dispatch<SetStateAction<boolean>>,
  setSecondTry: Dispatch<any>,
  setShowWarningCurrency: Dispatch<any>,
  setShowResponse: Dispatch<any>
  tryNumber: any,
  payAllAmount: boolean,
  tpvName: any
}

const Pago = (props: PropsPago) => {

  const {payAllAmount, tryNumber, tpvName} = props

  const {dpid, pendiente, pagado, dpentr, pvp, depositdatemove, currency} = props.datosReserva.data.reserva

  const langContext = React.useContext(LangContext);

  const [datosTpv, setdatosTpv] = useState<IdatosTpvFormulario>({} as IdatosTpvFormulario);
  const [showIframe, setshowIframe] = useState(false);
  
  const handlerSetShowError = (data: any) => {
    props.setShowError(data);   
  }
  
  let lastIFrameWidth
  let showWarningCurrencyAlready = false



  useEffect(() => {
 /*setTimeout(() => {
      setshowIframe(true);
      let divIframe: any = document.getElementById('iframePayment')
      divIframe.style.height = '780px'
      divIframe.style.width = '100%'

    }, 6000);*/
    window.addEventListener('message', function (event) {
      
      if (event.data != undefined) {

        if (typeof event.data === 'string' && event.data.indexOf("iframe")>-1) {
          
          let json = JSON.parse(event.data)
          let divIframe: any = document.getElementById('iframePayment')

          if (json.iframe.height != "0px") {
            
            if ( `${lastIFrameWidth}px` == json.iframe.width){

              const lastIFrameHeigth = parseInt(json.iframe.height, 10) 
  
              const showWarning = (lastIFrameHeigth >= 640 && lastIFrameHeigth <= 675) ? true : ((lastIFrameHeigth >= 555 && lastIFrameHeigth <= 560) ? false : showWarningCurrencyAlready)

              if (showWarning == !showWarningCurrencyAlready) {
                props.setShowWarningCurrency(showWarning)
                showWarningCurrencyAlready = showWarning
              }

            }

            //divIframe.style.height = json.iframe.height
           divIframe.style.height = '780px'
           // divIframe.style.width = json.iframe.width

          }

          lastIFrameWidth = parseInt(json.iframe.width, 10) 

          setshowIframe(true);
        }
      }

      if (event.data.result != 'undefined') {
        
        if (event.data.result == "ok" || event.data.result == "ko") {

          if (event.data.result == "ko"){
            props.setShowResponse({showResult: true, message: 'KO'})
          }
          else {
            props.setShowResponse({showResult: true, message: 'OK'})
          }

        } 
      }
    }, false);

    if (datosTpv.urlAction == undefined) {
      fetchPago(null)
    }

    if (showIframe) {
      props.setLoading(false)
      props.setShowPago(true)
    }

    return () => {
      window.removeEventListener('message', function (event) { })
    }
    //props.setShowError(true);
  }, [showIframe])

  const fetchPago = async (event: any) => {

    if (event) {
      event.preventDefault();
    }

    let tries =  tryNumber ? tryNumber : "1";

    const data: any = {
      OrderIdOwn: dpid, 
      Language: langContext.locale, 
      Amount: payAllAmount ? (pvp - pagado) : pendiente, 
      Currency: currency, 
      Locator: props.datosReserva.localizador, 
      DateBooking: moment(dpentr, 'YYYYMMDD').format(), 
      HotelId: props.datosReserva.data.hotel.dphote, 
      DepositDateMove: depositdatemove, 
      TryNumber: tries,
      TPVName: tpvName
    };

    fetch(`${process.env.URL_API_ENDPOINT}/api/TPV/Pay`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    )
      .then(response => response.json()
        .then(data => {
          response.json

          if (typeof data.code !== "undefined") {
            handlerSetShowError({ showError: true, code: data.code});
          }
          else if (data.resultResponse.idError == 1) {
            setdatosTpv({ ...data })
            //setshowIframe(true)
            //props.setShowPago(true)
          }

        }
        )
      )
  };

  return (

    <React.Fragment>

      {datosTpv.urlAction != undefined &&
        <div id="iframePayment" className="iframe-container" style={{ display: showIframe ? 'block' : 'none'}}>
         
          <PaymentView url_param={datosTpv.urlAction} values={datosTpv.inputs} showIframe={setshowIframe} nameForm='paymentForm'></PaymentView>

        </div>
      }

    </React.Fragment>

  )
}

export default Pago;
