import React, {SetStateAction , Dispatch, useState, useEffect}  from "react"
import * as IFrame from "../IframePayment";
import { ConfigTpv } from "../../interfaces/datosTpvFormulario";
import { filterProps } from "react-intl/dist/utils";

type Props = {
    values:ConfigTpv;
    url_param:string;
    showIframe: Dispatch<SetStateAction<boolean>>,
    nameForm:string
}

export const PaymentView = (prop: Props) => { 
    const url = prop.url_param;
 
    const method = "post";
    const data = prop.values;

    const onFinished = () : void => {};

   
    return <IFrame.Form showIframe={prop.showIframe} name={prop.nameForm} onFinished={onFinished} config={{ url, method, data }} />;
};