import React, { useState, useEffect, Dispatch } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Formulario from './Formulario/Formulario';
import Spinner from './Spinner/Spinner';
import { useIntl, FormattedHTMLMessage, IntlShape } from 'react-intl';
import moment from 'moment';
import 'sweetalert/dist/sweetalert.css';
import IDatosReserva from '../interfaces/datosReserva';
import IDatosEntrada from '../interfaces/datosEntrada';
import { Modal } from "react-bootstrap";
import { copyToClipboard } from '../utilities/Copy';
import ShowError from './ShowError';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import '../App.css';
import ManagementPaymentMethods from './ManagementPaymentMethods/ManagementPaymentMethods';

type PropsHome = {
    setSecondTry: Dispatch<any>
  }


const Home = (props: PropsHome) => {

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let uid: string | null = params.get('uid');
    let localizador: string | null = params.get('localizador');
    let fecha: string | null = params.get('fecha');
    let tryNumber: string  | null = params.get('tryNumber');

    const intl: IntlShape = useIntl();

    const [showError, setShowError] = useState<boolean>(false);
    const [ShowPago, setShowPago] = useState<boolean>(false);
    const [datosReserva, setDatosReserva] = useState<IDatosReserva>({} as IDatosReserva);
    const [loading, setLoading] = useState<boolean>(false);
    const [datosFormulario, setDatosFormulario] = useState<IDatosEntrada>({
        localizador: localizador ? localizador : '',
        fecha: fecha ? moment(fecha, "DD/MM/YYYY").toDate() : undefined
    });
    const [show, setShow] = useState(false);

    const [ipv4Address, setIpv4Address] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleOnMouseEnterLeave = (type : any, typeMove: any) => {

        const imgIconCopy: any = type === 'email' ? document.getElementById('imgIconCopyEmail') : document.getElementById('imgIconCopyPhone')

        imgIconCopy.src = typeMove === 'enter'  ? require('../assets/icono-copiar.svg').default : require('../assets/icono-copiar-blue.svg').default
        
    }

    const handleSetLoading = (showSpinner) => {

        const  divFormulario: any = document.getElementById('showFormulario')

        if (showSpinner){
            divFormulario.style.display = 'none'
        }
        else{
            divFormulario.style.display = 'block'
        }

        setLoading(showSpinner);
    }

    const handleSetDatosReserva = (data) => {
       
        if (data.code != 200 || (data.data && data.data.reserva && data.data.reserva.pendiente == 0)){
            setLoading(false);
        }

        if (data.data && data.data.reserva && data.data.reserva.pendiente == 0){
            
            const code = 9111;
            const hotel = data.data.hotel;
            const reserva = data.data.reserva;
            const localizador = data.localizador;

            setDatosReserva({...datosReserva, localizador, code, data :{hotel, reserva}})
            setShowError(true);
        }
        else
        {
            setDatosReserva(data);
        }
    }

    const handleShowError = ({showError, code}) => {

        if (code){
            setDatosReserva({...datosReserva, code})
        }

        setLoading(false);
        setShowError(showError);
    }

    useEffect(() => {
        
       (async function anyNameFunction() {
        setIpv4Address('')

      })();
      
    }, []);

    return (
        
        <>
            {
                ShowPago || loading ? null : 
                (
                    <div >
                        <h3 className="text-title" style={{ paddingTop: 4, fontSize:21 }}>
                            <FormattedHTMLMessage id="app.titulo" defaultMessage="Plataforma de pago electronico"/>
                        </h3>

                        {
                            datosReserva && datosReserva.code == 9111 ?
                            <br></br>
                            :
                            <p className="text-normal" style={{ paddingTop: 10 , paddingBottom:10, fontSize:16}}>
                                <FormattedHTMLMessage id="app.explicacion_formulario" defaultMessage="Datos de la reserva" />
                            </p>
                        }
                    </div>
                )
            }

            {
                loading ? <Spinner text='app.loading' /> : null
            }

            {
                showError ? datosReserva.code == 9111 ? (
                    <div>
                        <ShowError code= {datosReserva.code}/>
                        <br></br>
                        <br></br>
                        <div className="row">

                            <div className="col-10 col-md-8 col-sm-6" style={{ margin:'auto'}}>

                                <PaymentDetails datosReserva={datosReserva} />

                                <div id="Buttons">
                                    <a href={process.env.URL_RETURN_WEB}>
                                        <FormattedHTMLMessage id="app.ir_sitio_web" defaultMessage="Ir al sitio web" />
                                    </a>
                                    <a href="#" id="otherPayment" onClick={() => props.setSecondTry({localizador: '', fecha: null, tryNumber: 1, paymentWasOK: true})}>
                                        <FormattedHTMLMessage id="app.realizar_otro_pago" defaultMessage="Realizar otro pago" />
                                    </a>
                                </div>

                            </div>

                        </div>

                    </div>
                ) : <ShowError code= {datosReserva.code}/>
                :null
            }

            <div style={{clear:'both'}}></div>

            <div id="showFormulario" style={{ display: 'block'}}>
                {
                    (!datosReserva.data || !ShowPago) &&
                        <Formulario data={{
                            datosFormulario,
                            setDatosFormulario,
                            setDatosReserva : handleSetDatosReserva,
                            setLoading: handleSetLoading,
                            setShowError
                        }} />
                }
            </div>

            {(!datosReserva.data || !ShowPago) &&
                <p className="text-normal" style={{ paddingTop: 33 }}>
                    <img style={{ paddingRight: 10 }} src={require('../assets/icono-candado-circular.svg').default}></img>
                    <FormattedHTMLMessage id="app.pago_seguro"
                        defaultMessage="Pago electrónico seguro" />
                </p>
            }

            {datosReserva.data  && datosReserva.data.reserva.pendiente > 0 && !showError ?
                <ManagementPaymentMethods datosReserva={datosReserva}
                    setShowError={handleShowError}
                    setLoading={setLoading}
                    setShowPago={setShowPago}
                    setSecondTry = {props.setSecondTry}
                    tryNumber = {tryNumber ? tryNumber : "1"}
                />
                : null
            }

            {
                <p className="text-dorado" style={{paddingTop:'5px'}}>
                    <a className="text-dorado" href="#" onClick={handleShow} id="needHelp" style={{display: !loading ? 'block' : 'none'}} >
                        <FormattedHTMLMessage id="app.att_mensaje"
                            defaultMessage="Necesita ayuda" />
                    </a>
                </p>
            }

            <Modal show={show} onHide={handleClose} animation={true} centered={true}  >
                <Modal.Header closeButton style={{ borderBottom: 0, paddingBottom:0 }}>
                    <Modal.Title className='ModalTitle'>
                        <div id="divNeedHelpTitle">
                            <FormattedHTMLMessage id="app.att" defaultMessage="Atención al cliente" />
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className='ModalBody'>

                <div id ="divNeedHelp"> 

                    <p>
                        <FormattedHTMLMessage id="app.descripcion_contacto" defaultMessage="Puede ponerse en contacto con nosotros" />
                    </p>

                    <p style={{paddingTop:'10px'}}>
                        
                        <img style={{ paddingRight: 10 }} src={require('../assets/icono-phone.svg').default}></img>
                        
                        <a style={{color:'rgba(0,29,48,1)'}} href="tel: +34950335335">
                            <FormattedHTMLMessage id="app.telefono_contacto" defaultMessage="+34950335335" />
                        </a>
                        
                        <a href="#" onClick={copyToClipboard(intl.messages['app.telefono_contacto'].toString())}>
                            <img id='imgIconCopyPhone' style={{ paddingLeft: 10}} src={require('../assets/icono-copiar-blue.svg').default } 
                            onMouseEnter = { () => {handleOnMouseEnterLeave('phone', 'enter')}}
                            onMouseLeave = { () => {handleOnMouseEnterLeave('phone', 'leave')}}></img>
                        </a>
                    </p>

                    <hr/>

                    <p style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        
                        <img style={{ paddingRight: 10 }} src={require('../assets/icono-email.svg').default}></img>
                        
                        <a style={{color:'rgba(0,29,48,1)'}} href="mailto: reservas@senatorhr.com">
                            <FormattedHTMLMessage id="app.email_contacto" defaultMessage="reservas@senatorhr.com" />
                        </a>

                        <a href="#" onClick={copyToClipboard(intl.messages['app.email_contacto'].toString())}>
                            <img id='imgIconCopyEmail' style={{ paddingLeft: 6, paddingRight: 13 }} src={require('../assets/icono-copiar-blue.svg').default} 
                            onMouseEnter = { () => {handleOnMouseEnterLeave('email', 'enter')}}
                            onMouseLeave = { () => {handleOnMouseEnterLeave('email', 'leave')}}></img>
                        </a>
                    </p>

                </div>
                </Modal.Body>
               
            </Modal>
            
        </>
        
    );

}

export default Home;