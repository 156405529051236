import React from 'react';
import './Footer.css';
import { useIntl, FormattedHTMLMessage, IntlShape } from 'react-intl';


const Footer = () => {
    const intl: IntlShape = useIntl();

    return (
        <footer className="footer" id="footer">
            <div className="box-shadow-footer">
                            <span>{intl.messages['app.footer'].toString()} v.2.0</span>
                </div>    
        </footer>
    );
}


export default Footer;