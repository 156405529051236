import * as React from "react";
import './Form.css';


export interface Config {
    data: { [ k: string ]: any; };
    method: "GET" | "POST" | "get" | "post",
    url: string;
}

export interface FormProps {
    name: string;
    config: Config,
    showIframe: any,
    onFinished: () => void
}

export const Form: React.FunctionComponent<FormProps> = React.memo(({ config, name, showIframe, onFinished }) => {
    const [ isSent, setIsSent ] = React.useState<boolean>(false);
    const [isLoaded, setIsLoaded]= React.useState<boolean>(false);
    const formRef = React.useRef<HTMLFormElement | null>(null);

    React.useEffect(()=>{
        if (config && config.data) {
            
            //form.submit();
            console.log("1", config);
            setIsLoaded(true);
        }
    },[config])

    React.useEffect(() => {
        const submitOnMount = (form: HTMLFormElement) => {
          if (!form) {
            return;
          }
          
          if (isLoaded) {
            console.log("3");
            form.submit();
            //setIsSent(true);
            let divIframe: any = document.getElementById('iframePayment')
            divIframe.style.height = '780px'
            divIframe.style.width = '100%'
            showIframe(true);
          } else {
            console.log("3b");
          }
        };
      
        if (formRef && formRef.current){
            submitOnMount(formRef.current); // Asegúrate de utilizar la referencia correcta a tu formulario
        }
      
    }, [isLoaded]);

    const handleIframeMessage = (event: MessageEvent) => {
        console.log("Iframe event", event);
        
        if (event.data === 'go_home') {
            window.location.href = "/";
        }
      };

    React.useEffect(() => {
        // Agregar un event listener para escuchar mensajes del iframe
        window.addEventListener('message', handleIframeMessage);
    
        return () => {
          // Eliminar el event listener al desmontar el componente
          window.removeEventListener('message', handleIframeMessage);
        };
      }, []);


    return (
        <>
            {isLoaded && ( 
                <>
                    <iframe name={name} className="iframeCSS"    frameBorder="0" scrolling="no" onLoad={onFinished} />
                    <form target={name} action={config.url} method={config.method} ref={formRef} >
                        {Object.keys(config.data).map((key) => (
                            <input
                                type={"hidden"}
                                name={key}
                                key={key}
                                value={
                                    ("string" === typeof config.data[ key ])
                                        ? config.data[ key ]
                                        : JSON.stringify(config.data[ key ])
                                }
                            />
                        ))}
                    </form>
                </>
            )}
        </>
    )
});

Form.displayName = "IFrameForm";
