import React from 'react';
import "./PaymentSummary.css"
import datosReserva from '../../interfaces/datosReserva';
import { FormattedHTMLMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import moment from "moment";

type PropsPaymentSummary = {
    datosReserva: datosReserva,
    payAllAmount: boolean
}

const PaymentSummary= (props: PropsPaymentSummary) => {

    const {payAllAmount} = props

    const {pendiente, pagado, eN_HOTEL, depgdo, deftop, pvp, simbol} = props.datosReserva.data.reserva

    const amount_ToPay = payAllAmount ? (pvp - pagado) : pendiente

    const amount_ToPayInHotel = payAllAmount ? 0 : eN_HOTEL
    
    return (
        <React.Fragment>

            <div className="fondoDetalle">
                <div className="col-8 col-md-9 hidden-xs text-left">
                    <span className="fontBold">
                        <FormattedHTMLMessage
                        id="app.pago_h4_prepago"
                        defaultMessage="FALTA LA TRADUCCION: pago_h4_prepago"
                        />
                    </span>
                </div>
            </div>
            
            {/* Importe a pagar */}
            <div className="row pt-3 fondoDetalle fontNomal">
                <div className="col-8 col-md-9 hidden-xs text-left fontNormal">
                    <label>
                        <FormattedHTMLMessage
                            id="app.importeTotal"
                            defaultMessage="FALTA LA TRADUCCION: total pagar"
                        />
                    </label>
                </div>
                <div className="col-4 col-md-3 hidden-xs text-right fontNormal">
                    <span>
                        <NumberFormat
                            value={amount_ToPay}
                            displayType={"text"}
                            decimalScale={2}
                            suffix={simbol}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            fixedDecimalScale={true}
                        />
                    </span>
                </div>
            </div>

            {/* Importe Pagado */}
            <div className="row pt-3 fondoDetalle fontNomal">
                <div className="col-8 col-md-9 hidden-xs text-left fontNormal">
                    <label>
                        <FormattedHTMLMessage
                            id="app.pago_lbl_abonado"
                            defaultMessage="FALTA LA TRADUCCION: pago_lbl_abonado"
                        />
                    </label>
                </div>
                <div className="col-4 col-md-3 hidden-xs text-right fontNormal">
                    <span>
                        <NumberFormat
                            value={pagado}
                            displayType={"text"}
                            decimalScale={2}
                            suffix={simbol}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            fixedDecimalScale={true}
                        />
                    </span>
                </div>
            </div>

            {/* Pago en Hotel */}
            <div className="row pt-3 fondoDetalle fontNomal">
                <div className="col-8 col-md-9 hidden-xs text-left text-left fontNormal">
                    <label>
                        <FormattedHTMLMessage
                            id="app.pago_lbl_en_hotel"
                            defaultMessage="FALTA LA TRADUCCION: pago_lbl_en_hotel"
                        />
                    </label>
                </div>

                <div className="col-4 col-md-3 hidden-xs text-right fontNormal" style={{paddingBottom:'25px'}}>

                    <span>
                        <NumberFormat
                            value={eN_HOTEL < 0 ? 0 : amount_ToPayInHotel}
                            displayType={"text"}
                            decimalScale={2}
                            suffix={simbol}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            fixedDecimalScale={true}
                        />
                    </span>
                </div>
            </div>

            <div style={{marginLeft: '5px', marginRight: '5px', border: '0.5px solid #C6D2D9', height: '1px', backgroundColor: 'white'}}></div>

            <div className="row pt-3 fondoDetalle">

                <div className="col-7 col-md-9 hidden-xs text-left fontBold">
                    <label>
                        <FormattedHTMLMessage
                            id="app.pago_lbl_prepago"
                            defaultMessage="FALTA LA TRADUCCION: total pagar"
                        />
                    </label>
                </div>

                {depgdo == "N" ? (

                <div className="col-5 col-md-3 hidden-xs text-right fontBold">
                    <span>
                        <NumberFormat
                            value={amount_ToPay}
                            displayType={"text"}
                            decimalScale={2}
                            suffix={simbol}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            fixedDecimalScale={true}
                        />
                    </span>
                </div>
                ) : (
                    <h4 style={{paddingLeft:'70px'}}>
                        <FormattedHTMLMessage
                            id="app.pago_h4_pagado"
                            defaultMessage="FALTA LA TRADUCCION: pago_h4_pagado"
                        />
                    </h4>
                )}
            </div>

            <div className="row pt-3 fondoDetalle">
                <div className="col-12 col-md-12 hidden-xs text-left fontLimite">
                    <p>
                        *<FormattedHTMLMessage id="app.condiciones_fecha_limite" defaultMessage="La fecha límite para realizar el pago es: " />
                        <strong>{moment.utc(deftop, 'YYYYMMDD').format("DD/MM/YYYY")}</strong>
                    </p>
                </div>
            </div>

        </React.Fragment>
    )
}

export default PaymentSummary;