import React, { useContext } from "react";
import "./Header.css";
import { LangContext } from "../Context/Context";

const Header = () => {
  
  const {locale, setLocale} = useContext(LangContext)
  const logo = require('../../assets/logo-senator.svg').default;

  return (
    <header>
      <div className="container-fluid border-bottom box-shadow mb-3 navbar">
        <div className="container px-2">
        <div className="row container px-5">
          <div className="col-9 col-md-10">
            <img className="logoSenator" src={logo}  onClick={() => window.location.replace( process.env.URL_RETURN_WEB + '')}></img>
          </div>
          <div className="col-9 col-md-10" style={{maxWidth:"0", paddingRight: "2%", paddingBottom: "0.5%"}}>
            <img className="logoFlag"  src={
             (locale == "es-ES") ? require('../../assets/idioma-espanol.svg').default :  require('../../assets/idioma-ingles.svg').default
                      }
             >
            </img>
          </div>  
          <div className="col-1 col-md-1">
            <select
              id="lang" style={{border:"none",padding:"0 0",width:"auto"}}
              className="form-control lbl-inputs"
              onChange={
                (l) => {setLocale(l.target.value);}
            }
              value={locale}
            >
              <option value="es-ES" >ES</option> 
              <option value="en-GB">EN</option>
            </select>
          </div>
        </div>
      </div>
      </div>
    </header>
  );
};

export default Header;
