import React, { useEffect, SetStateAction, Dispatch } from 'react';
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { DateUtils } from 'react-day-picker';
import moment from 'moment';
import './formulario.css';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { LangContext } from '../Context/Context';
import IDatosEntrada from '../../interfaces/datosEntrada';
import IDatosReserva from '../../interfaces/datosReserva';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { es,enGB} from 'date-fns/locale';
import MomentLocaleUtils from 'react-day-picker/moment';

const locales: any = {"es": es, "en": enGB}

const parseDate = (str: string, format: string, locale: string) => {

  const parsed = dateFnsParse(str, format, new Date(), {locale: locales[locale]} );
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

const formatDate = (date: Date, format: string, locale: string) => {
  return dateFnsFormat(date, format, {locale: locales[locale] });
}

type Props = {
    data:DataProps;
}

type DataProps = {
    datosFormulario: IDatosEntrada,
    setDatosFormulario: Dispatch<SetStateAction<IDatosEntrada>>,
    setDatosReserva: Dispatch<SetStateAction<IDatosReserva>>
    setLoading: Dispatch<SetStateAction<boolean>>,
    setShowError: Dispatch<SetStateAction<boolean>>
}

const Formulario = (props: Props) => {

    const handlerOnKeyUp = () => {

        let txtLocalizador: any = document.getElementById('txtLocalizador')
        let imgIconDelete: any = document.getElementById('imgIconDelete')

        if (txtLocalizador.value.length > 0){
            imgIconDelete.src = require('../../assets/icono-aspa.svg').default
            imgIconDelete.style.visibility =  'visible'
        }
        else{
            imgIconDelete.style.visibility =  'hidden'
        }
    }

    const handleDeleteLocator = () => {
        props.data.datosFormulario.localizador = ''

        let txtLocalizador: any = document.getElementById('txtLocalizador')
        txtLocalizador.value = ''
        txtLocalizador.focus();

        let imgIconDelete: any = document.getElementById('imgIconDelete')
        imgIconDelete.style.visibility =  'hidden'
    }

    const handlerOnMouseEnter = () => {
        let imgIconDelete: any = document.getElementById('imgIconDelete')
        imgIconDelete.src = require('../../assets/icono-aspa-enter.svg').default
    }

    const handlerOnMouseLeave = () => {
        let imgIconDelete: any = document.getElementById('imgIconDelete')
        imgIconDelete.src = require('../../assets/icono-aspa.svg').default
    }

    const handlerDayMouseEnter = (day, modifiers, e) => {
        e.target.style.background = e.target.getAttribute('aria-selected') == 'true' ? '#D9B74C' : '#EDE1B9';
        e.target.style.color = 'white';
    }

    const handlerDayMouseLeave = (day, modifiers, e) => {
       
        e.target.style.background = e.target.getAttribute('aria-selected') == 'true' ? '#c99900' : 'white';
        e.target.style.color = e.target.getAttribute('aria-selected') == 'true' ? 'white' : '#001D30';
    }

    const handleOnDayPickerShowHide = (showCalendar) => {
        let imgIconChevron: any = document.getElementById('imgIconChevron')

        imgIconChevron.src = showCalendar ? require('../../assets/chevron-select-up.svg').default : require('../../assets/chevron-select.svg').default
    } 
    
    useEffect(() => {
        if (props.data.datosFormulario.localizador.length > 0 && props.data.datosFormulario.fecha) {
            fetchDatosReserva(null);
        }

        let txtLocalizador: any = document.getElementById('txtLocalizador')
        txtLocalizador.focus();
    }, []);

    const langContext = React.useContext(LangContext);

    const fetchDatosReserva = async (event: any) => { 
        
        if (event) {
            event.preventDefault();
        }

        props.data.setLoading(true);
        props.data.setShowError(false);
        const fecha = moment.utc(props.data.datosFormulario.fecha as Date).format();
        
        fetch(`${process.env.URL_API_ENDPOINT}/api/TPV/${props.data.datosFormulario.localizador}/${fecha}`,
        {
	        'mode': 'cors',
	        'headers': {
            	'Access-Control-Allow-Origin': '*',
        	}
    	})
            .then(response => response.json())
            .then(data => {
                props.data.setDatosReserva(data);

                if (data.code != 200) {
                    props.data.setShowError(true);   
                    props.data.setLoading(false)                 
                }
                //props.data.setLoading(false);
            });                
    };
    
    return (
        <React.Fragment>
            <form onSubmit={fetchDatosReserva}>
                <div className="container" style={{paddingTop:'15px'}}>
                    <div className="row justify-content-center" style={{textAlign:"left"}}>
                        <div className="col-12 col-md-8">
                            <label className="lbl-inputs"><FormattedHTMLMessage id="app.formulario_lbl_localizador" defaultMessage="Localizador" /></label>
                            <div className="divLocator">
                                <input
                                    maxLength = {15}
                                    id="txtLocalizador"
                                    name="localizador"
                                    type="text"
                                    value={props.data.datosFormulario.localizador}
                                    className="form-control"
                                    onChange={e =>
                                        props.data.setDatosFormulario({
                                            ...props.data.datosFormulario,
                                            localizador:e.target.value
                                        })
                                    }
                                    required
                                    placeholder={ langContext.locale == 'es-ES' ? 'Introduzca el localizador' : 'Enter the locator' }
                                    onKeyUp = {handlerOnKeyUp}
                                />
                                <img id="imgIconDelete" src = {require('../../assets/icono-aspa.svg').default} onClick={handleDeleteLocator} onMouseEnter={handlerOnMouseEnter} onMouseLeave={handlerOnMouseLeave} style={{visibility:'hidden'}}></img>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center" style={{paddingTop:18,textAlign:"left"}}>
                            <div className="col-12 col-md-8">
                                <label className="lbl-inputs"><FormattedHTMLMessage id="app.formulario_lbl_fecha" defaultMessage="Fecha" /></label>
                                <div className="divDatePicker">
                                    <DayPickerInput       
                                        format="dd/MM/yyyy"
                                        formatDate= {formatDate}
                                        parseDate={parseDate}
                                        value={props.data.datosFormulario.fecha}
                                        
                                        onDayChange={e =>
                                            props.data.setDatosFormulario({ ...props.data.datosFormulario, fecha: moment(e).toDate() })
                                        }
                                        onDayPickerHide = {() => handleOnDayPickerShowHide(false)}
                                        
                                        onDayPickerShow = {() => handleOnDayPickerShowHide(true)}

                                        inputProps={{
                                            required: true,
                                            className: "form-control",
                                            placeholder: langContext.locale == 'es-ES' ? 'Seleccione fecha de llegada' : 'Select date of arrival' ,
                                            readOnly: true
                                        }}

                                        dayPickerProps={{
                                            locale:  langContext.locale,
                                            localeUtils: MomentLocaleUtils,
                                            disabledDays: {
                                                before: new Date()
                                            },
                                            modifiers : {dateToday:new Date()},
                                            modifiersStyles : {
                                                dateToday: {
                                                    color: '#001D30',
                                                    background: ''
                                                },
                                                selected: {
                                                    background: '#c99900',
                                                    color: 'white'
                                                },
                                                disabled:{
                                                    color: '#001D30',
                                                    opacity: 0.2
                                                }
                                            },
                                            onDayMouseEnter: handlerDayMouseEnter,
                                            onDayMouseLeave: handlerDayMouseLeave
                                        }}
                                    />                        
                                    <img id="imgIconChevron" src = {require('../../assets/chevron-select.svg').default}></img>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center" style={{paddingTop:50}}>
                            <div className="col-12 col-md-8">
                                <button type="submit" className="btn btn-primary btn-lg btn-continuar">
                                    <FormattedMessage id="app.formulario_btn_comprobar" defaultMessage="Continuar" />
                                </button>
                            </div>
                        </div>
                    </div>
            </form>
        </React.Fragment>
    );
}

export default Formulario;