import React from "react";
import { useIntl, IntlShape } from 'react-intl';

const WarningCurrency= () => {

    const intl: IntlShape = useIntl();

    return (
        <div className="alert alert-warning mt-3 text-body" style={{border:'1px solid #C99900'}}>
            <div style = {{float:'left', height:'50px'}}>
                <img style={{ paddingRight: 10 }} src={require('../../assets/icono-info.svg').default}></img>
            </div>
            <div style={{textAlign:'left'}}>
                <p style={{display:'inline', }}>
                    {intl.messages['app.PagoDivisa'].toString()}
                </p>
            </div>
        </div>
    )
}

export default WarningCurrency;