import React from "react"
import OKPage from "./pages/OK"
import KOPage from "./pages/KO"

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TPVPage from "./pages/TPV";

const RoutesApp = () : JSX.Element => {


    return(
        <Router>
            <Routes>
                <Route path="/" element={<TPVPage />} />
                <Route path="/ok" element={<OKPage />} />
                <Route path="/ko" element={<KOPage />} />
            </Routes>
        </Router>
    )

}

export default RoutesApp;   