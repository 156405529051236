import React, { useEffect, Dispatch, SetStateAction, useState } from "react";
import datosReserva from "../../interfaces/datosReserva";
import moment from "moment";
import { useIntl, FormattedHTMLMessage, IntlShape } from 'react-intl';
import Spinner from "../Spinner/Spinner";
import { Modal } from "react-bootstrap";
import { copyToClipboard } from '../../utilities/Copy';
import './BankTransfer.css'

type PropsPayManagementBankTransfer = {
    datosReserva: datosReserva,
    setShowError: Dispatch<SetStateAction<any>>, 
    setLoaded: Dispatch<SetStateAction<any>>, 
    showMessageResult: Dispatch<SetStateAction<any>>, 
    numberClicks: any
}

const BankTransfer = (props: PropsPayManagementBankTransfer) => {
        
    const intl: IntlShape = useIntl();

    const [bankAccounts, SetBankAccounts] = useState<any>({showText: false, accountCTA: '', accountSWIFT: '', bank: ''});

    const {setLoaded, showMessageResult} = props
    const {showTextLoaded, accountCTA, accountSWIFT, bank} = bankAccounts

    const {dpfgrb, dptitu} = props.datosReserva.data.reserva
    const {localizador : locator} = props.datosReserva
    const [showAccount, setShowAccount] = useState(false);

    const handleShow = () => {
        showMessageResult('BankTransfer')
    }

    const handleCloseAccount = () => setShowAccount(false);   

    const handleOnMouseEnterLeave = (type, typeMove) => {    
        
        let imgIconCopy: any = type === 'concept' ? document.getElementById('imgIconConcept') : type === 'account1' ?  document.getElementById('imgIconAccount1') 
                                : type === 'account2' ? document.getElementById('imgIconAccount2') : type === 'swift' ? document.getElementById('imgIconSwift') :
                                document.getElementById('imgIconCopyEmail')

        imgIconCopy.src = typeMove === 'enter' ? require('../../assets/icono-copiar.svg').default : require('../../assets/icono-copiar-blue.svg').default      
    }

    const fetchCheckOut = async () => {

        const data: any = {           
          locator: props.datosReserva.localizador,           
          bookingDate: dpfgrb         
        };                

        const response = await fetch(`${process.env.URL_API_ENDPOINT}/api/TPV/GetAccountBankByLocator`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
         })

        const dataAccounts = await response.json()        
       
        if (dataAccounts.success) {            
            setShowAccount (true);  
            setLoaded(true);
        }  

        SetBankAccounts({showTextLoaded: true, accountCTA : dataAccounts.cta, accountSWIFT :  dataAccounts.swift, bank: dataAccounts.banco})            
    };     

    useEffect(() => {  

        if (!showTextLoaded){
            //console.log('useEffectF', showTextLoaded)
            fetchCheckOut()    
        }
        else{
            setShowAccount (true);  
            setLoaded(accountCTA && accountSWIFT && bank);
            //console.log('useEffectT', showTextLoaded)
        }
    }, [props.numberClicks])

    return (

        <>
            {
                !showTextLoaded && (   
                    <>
                        <Spinner text='app.method_loading'/> 
                    </>
                )
            }

            {
                showTextLoaded && 
                <> 
                    {   accountCTA &&  accountSWIFT && bank ?
                        (<div className="BankDetails">              
                                <>
                                    <Modal show={showAccount} onHide={handleCloseAccount} animation={true} centered={true} >
                                        <Modal.Header closeButton>
                                            <Modal.Title className='ModalTitle'>
                                                <div id="divAccountTitle"><FormattedHTMLMessage id="app.bankTransferInformation_h5_tituloAccount"  defaultMessage="Transferencia bancaria" /></div>
                                        </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='ModalBody'>   

                                        <div id ="divAccount"> 

                                            <div>
                                                <FormattedHTMLMessage id="app.bankTransferAccountDataConcept" defaultMessage="Indicando como concepto" />                           
                                                <div className= "AccountData">{locator} - {dptitu}                      
                                                    <a href="#" onClick={copyToClipboard(`${locator} - ${dptitu}`)}>
                                                        <img id='imgIconConcept' style={{ paddingLeft: 10}} src={require('../../assets/icono-copiar-blue.svg').default } 
                                                        onMouseEnter = { () => {handleOnMouseEnterLeave('concept', 'enter')}}
                                                        onMouseLeave = { () => {handleOnMouseEnterLeave('concept', 'leave')}}></img>
                                                    </a>
                                                </div>
                                            </div>  


                                            <ul style={{ paddingLeft: 20, marginBottom: 5}}>
                                                <li className= "AccountDataBig"><FormattedHTMLMessage id="app.bankTransferAccountDataLine1" defaultMessage="Transferencia nacional" /></li> 
                                            </ul> 
                                            <div>
                                                <FormattedHTMLMessage id="app.bankTransferAccountName"  values={{ bank: bank}} defaultMessage="Cuenta" />  
                                                <div className= "AccountData">{accountCTA}                      
                                                    <a href="#" onClick={copyToClipboard(`${accountCTA}`)}>
                                                        <img id='imgIconAccount1' style={{ paddingLeft: 10}} src={require('../../assets/icono-copiar-blue.svg').default } 
                                                        onMouseEnter = { () => {handleOnMouseEnterLeave('account1', 'enter')}}
                                                        onMouseLeave = { () => {handleOnMouseEnterLeave('account1', 'leave')}}></img>
                                                    </a>
                                                </div> 
                                            </div> 

                                            <ul style={{ paddingLeft: 20, marginBottom: 5}}>
                                                <li className= "AccountDataBig"><FormattedHTMLMessage id="app.bankTransferAccountDataLine2" defaultMessage="Transferencia desde el extranjero" /></li>
                                            </ul>
                                            <div>
                                                <FormattedHTMLMessage id="app.bankTransferAccountName" values={{ bank: bank}} defaultMessage="Cuenta" />   
                                                <div className= "AccountData">{accountCTA}   
                                                    <a href="#" onClick={copyToClipboard(`${accountCTA}`)}>
                                                        <img id='imgIconAccount2' style={{ paddingLeft: 10}} src={require('../../assets/icono-copiar-blue.svg').default } 
                                                        onMouseEnter = { () => {handleOnMouseEnterLeave('account2', 'enter')}}
                                                        onMouseLeave = { () => {handleOnMouseEnterLeave('account2', 'leave')}}></img>
                                                    </a>   

                                                </div>
                                            </div>  
                                            <div>
                                                <FormattedHTMLMessage id="app.bankTransferSwift" defaultMessage="SWIFT" />                               
                                                <div className= "AccountData">{accountSWIFT}                      
                                                    <a href="#" onClick={copyToClipboard(`${accountSWIFT}`)}>
                                                        <img id='imgIconSwift' style={{ paddingLeft: 10}} src={require('../../assets/icono-copiar-blue.svg').default } 
                                                        onMouseEnter = { () => {handleOnMouseEnterLeave('swift', 'enter')}}
                                                        onMouseLeave = { () => {handleOnMouseEnterLeave('swift', 'leave')}}></img>
                                                    </a>
                                                </div> 
                                            </div>  
                                        

                                            <div style={{textAlign: 'center'}}>
                                                <button id="btnBankTransfer" onClick={handleShow} ><FormattedHTMLMessage id="app.btnGot" defaultMessage="Entendido" /></button>
                                            </div>   
                                        </div>

                                        </Modal.Body>
                                        
                                    </Modal>
                                </>

                        </div> )
                        :
                        (<p><FormattedHTMLMessage id="app.bankTransferInformationUnsuccess" defaultMessage="No se puede pagar por transferencia" /></p>)
                    }
                    
                </>
            }
        </>
    )
}

export default BankTransfer;