import React from 'react';
import "./PaymentDetails.css"
import NumberFormat from 'react-number-format';
import { FormattedHTMLMessage } from 'react-intl';
import datosReserva from '../../interfaces/datosReserva';

type PropsPaymentDetails = {
    datosReserva: datosReserva
}

const PaymentDetails = (props: PropsPaymentDetails) => {

    const {simbol} = props.datosReserva.data.reserva
     
    return (
        <React.Fragment>
        <div className="fondo_detalle">
            <div className="row">
                <div className="col-lg-12 col-md-2 col-sm-4 hidden-xs text-left pl-4">
                    <span className="fontTitle">
                        <FormattedHTMLMessage id="app.resumen_h4_titulo" defaultMessage="Detalles" />
                    </span>
                </div>
            </div>

            <div className="row pt-3 fontRegular">
                <div className="col-12 col-md-6 col-sm-6 hidden-xs text-left pl-4 fontRegular">
                    <label><FormattedHTMLMessage id="app.paymentDetails_lbl_localizador" defaultMessage="Localizador" /></label>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-sm-6 hidden-xs text-left pl-4">
                     <span className="fontRegularBold">{props.datosReserva.localizador}</span>
                </div>
            </div>

            <div className="row pt-3 fontRegular">
                <div className="col-12 col-md-6 col-sm-6 hidden-xs text-left pl-4 fontRegular">
                    <label><FormattedHTMLMessage id="app.paymentDetails_lbl_establecimiento" defaultMessage="Establecimiento" /></label>
                </div>        
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-sm-6 hidden-xs pl-4 text-left">
                    <span className="fontRegularBold">{props.datosReserva.data.hotel.hotno}</span>
                </div>
            </div>

            <div className="row pt-3 fontRegular">
                <div className="col-12 col-md-6 col-sm-6 hidden-xs text-left fontRegular pl-4">
                    <label><FormattedHTMLMessage id="app.paymentDetails_lbl_titular" defaultMessage="Titular" /></label>
                </div>     
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-sm-6 hidden-xs text-left pl-4">
                <span className="fontRegularBold">{props.datosReserva.data.reserva.dptitu}</span>
                </div>
            </div>

            <div className="row pt-3 pr-3" style={{paddingBottom:'10px'}}>
                <div className="col-7 col-md-6 col-sm-6 hidden-xs text-left pl-4 fontPVP">
                        <label><FormattedHTMLMessage id="app.paymentDetails_lbl_pvp" defaultMessage="P.V.P." /></label>
                </div>
                <div className="col-5 col-md-6 col-sm-6 hidden-xs text-right fontPVP">
                <span className="fontPVP" style={{textAlign:'right'}}>
                        <NumberFormat 
                            value={props.datosReserva.data.reserva.pvp} 
                            displayType={'text'} 
                            decimalScale={2} 
                            suffix={simbol}
                            thousandSeparator={'.'} 
                            decimalSeparator={','} 
                            fixedDecimalScale={true} />
                    </span>
                </div>
            </div>

        </div>

        </React.Fragment>
    );
}

export default PaymentDetails;