import React, { useEffect, Dispatch, SetStateAction } from "react";
import datosReserva from "../../interfaces/datosReserva";
import moment from "moment";
import { LangContext } from "../Context/Context";
import { FormattedHTMLMessage, FormattedMessage, useIntl,  IntlShape} from 'react-intl';
import './Aplazame.css'
import ReactTooltip from "react-tooltip";

type PropsPayManagementAplazame = {
    datosReserva: datosReserva,
    setShowError: Dispatch<SetStateAction<any>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    payAllAmount: boolean
  }

  declare const window: any;

  const Aplazame = (props: PropsPayManagementAplazame) => {

      const {payAllAmount} = props

      const {dpid, pendiente, customeremail, pagado, dpentr, pvp, depositdatemove} = props.datosReserva.data.reserva

      const {datosReserva, setLoading} = props;

      const langContext = React.useContext(LangContext);

      const intl: IntlShape = useIntl();

      const handlerSetShowError = (data) => {
        props.setShowError(data);   
      }

      const fetchCheckOut = async (event: any) => {

        if (event) {
          event.preventDefault();
        }

        const data: any = {
          IdOrderExternal: dpid, 
          Language: langContext.locale, 
          Amount: payAllAmount ? (pvp - pagado) : pendiente, 
          Currency: 'EUR', 
          Locator: props.datosReserva.localizador, 
          CustomerEmail: customeremail,
          DateBooking: moment(dpentr, 'YYYYMMDD').format(), 
          HotelId: props.datosReserva.data.hotel.dphote, 
          DepositDateMove: depositdatemove, TryNumber: "1",
          FinantialCompanyName: 'Aplazame'
        };
    
        fetch(`${process.env.URL_API_ENDPOINT}/api/TPV/CreateCheckOutAplazame`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        }
        )
          .then(response => response.json()
            .then(data => {
                response.json
                
                setLoading(false)

                if (data.code || (data.resultResponse && data.resultResponse.idError != 1)) {
                  handlerSetShowError({ showError: true, code: data.code ? data.code : 9201});
                }
                else {
                
                  callAplazameCheckOut(data.checkoutID)
              
                }
              }
            )
          )
      };
      
      const handlerAplazame = () => {

        const btnAplazame: any = document.getElementById('btnAplazame')

        btnAplazame.disabled = true
        
        setLoading(true)
        fetchCheckOut(null)
      }

      const callAplazameCheckOut = (checkout_id: string) => {

        if (checkout_id){
          window.aplazame.checkout(checkout_id, {
            onStatusChange: function (result_status) {
    
              switch(result_status) {
              case 'success':
                console.log('el checkout ha sido completado correctamente')
                break
              case 'pending':
                console.log('el checkout ha terminado pero está pendiente de ser validado')
                break
              case 'ko':
                console.log('el proceso de pago ha sido rechazado por parte de Aplazame')
                break
              }
            },
            onClose: function (result_status) {
              switch(result_status) {
              case 'success':
                console.log('el checkout ha sido completado correctamente')
                break
              case 'pending':
                console.log('el checkout ha terminado pero está pendiente de ser validado')
                break
              case 'error':
                console.log('ha ocurrido un error al cargar el checkout')
                break
              case 'dismiss':
                console.log('el usuario ha cerrado el checkout sin haberlo completado')
                break
              case 'ko':
                console.log('el proceso de pago ha sido rechazado por parte de Aplazame')
                break
              }
            },
            })
        }
      }
      
      useEffect(() => {

        const script = document.createElement("script");
        const paramSandBox = process.env.ISPRODUCTION === 'true'? '&sandbox=false' : '&sandbox=true'
        script.src = `https://cdn.aplazame.com/aplazame.js?public-key=931f65afff672583ea545a96cb4a453135c7982b${paramSandBox}`;
        script.defer = true;
        script.async = true;
        script.onload = () => {
          // console.log('loading...', window.aplazame)
        };

        document.head.appendChild(script);

        setLoading(false)
        
      }, [])

      return (

        <React.Fragment>

          <div className="AplazameDetails">

            <p><FormattedHTMLMessage id="app.aplazameLine1" defaultMessage="" /></p>

            <button id="btnAplazame" onClick={handlerAplazame} >
              <FormattedMessage id="app.aplazameButton" defaultMessage="Aplázame" />
            </button>

            <img id="imgIconChevron" src = {require('../../assets/icono-infoGray.svg').default } data-tip data-for="registerTip"></img>

            <ReactTooltip id="registerTip" place="right" effect="solid">
              {intl.messages['app.aplazameDNIWarning'].toString()}
            </ReactTooltip>

          </div>

        </React.Fragment>

      )
  }

  export default Aplazame;