import React, { useState } from 'react';
import moment from 'moment';
import './condiciones.css';
import { FormattedHTMLMessage, useIntl, IntlShape } from 'react-intl';
import { Modal } from "react-bootstrap";
import Restriccion from '../../interfaces/restriccion';
import Cancelacion from '../../interfaces/cancelacion';
import datosReserva from '../../interfaces/datosReserva';
import Hotel from '../../interfaces/hotel';

type PropsCondiciones = { data: PropsCondicionesData }

type PropsCondicionesData = {
    fecha: number,
    restricciones: Restriccion[],
    cancelacion: Cancelacion[],
    showWarningCurrency: boolean,
    datosReserva: datosReserva,
    hotel: number,
}

const Condiciones = (props: PropsCondiciones) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const intl: IntlShape = useIntl();

    const renderTermsConditions = () => {

        const hotelType = props.data.datosReserva.data.hotel.dphote;
      
        if (hotelType) {
        switch (hotelType) {
          case 120:
          case 660:
          case 130:
          case 150:
          case 280:
          case 340:
          case 350:
          case 360:
          case 440:
          case 450:
          case 460:
          case 470:
          case 610:
          case 260:
            return (
              <>
                <span><FormattedHTMLMessage id="app.term_condition_3" defaultMessage="FALTA LA TRADUCCION: term_condition_3" /></span>
                <br />
                <br />
              </>
            );

            default:
            return (
              <>
                <span><FormattedHTMLMessage id="app.term_condition_1" defaultMessage="FALTA LA TRADUCCION: term_condition_1" /></span>
                <br />
                <br />
                <span><FormattedHTMLMessage id="app.term_condition_2" defaultMessage="FALTA LA TRADUCCION: term_condition_2" /></span>
              </>
            );
        }
    }
};

const renderCancellationConditions = () => {
    const hotelType = props.data.datosReserva.data.hotel.dphote;

    const hotelTypeExcluded = [120, 660, 130, 150, 280, 340, 350, 360, 440, 450, 460, 470, 610, 260];

    return (
        <>
            {(props.data.cancelacion && props.data.cancelacion.length > 0 && !hotelTypeExcluded.includes(hotelType)) ? (
                <div className="condiciones">
                    {props.data.cancelacion.map(c => (
                        <p key={c.dgline}>
                            <FormattedHTMLMessage
                                id="app.condiciones_p_cancelacion"
                                values={{ dias: c.dggcdi, horas: c.dggcho }}
                                defaultMessage="FALTA LA TRADUCCION: condiciones_p_cancelacion"
                            />
                            {c.dggcno > 0 ? (
                                <span>
                                    <FormattedHTMLMessage
                                        id="app.condiciones_p_cancelacion_importe_noche"
                                        values={{ precio: c.dggcno }}
                                        defaultMessage="FALTA LA TRADUCCION: condiciones_p_cancelacion_importe_noche"
                                    />
                                </span>
                            ) : (
                                <span>
                                    <FormattedHTMLMessage
                                        id="app.condiciones_p_cancelacion_porc_noche"
                                        values={{ porc: c.dggcpo }}
                                        defaultMessage="FALTA LA TRADUCCION: condiciones_p_cancelacion_porc_noche"
                                    />
                                </span>
                            )}
                        </p>
                    ))}
                    {props.data.restricciones.map(r => (
                        <p key={r.dtline}>{r.dttext}</p>
                    ))}
                    <p>
                        <FormattedHTMLMessage
                            id="app.condiciones_fecha_limite"
                            defaultMessage="La fecha límite para realizar el pago es: "
                        />
                        {moment.utc(props.data.fecha, 'YYYYMMDD').format("DD/MM/YYYY")}
                    </p>
                </div>
            ) : (
                <div className="condiciones">
                   <span><FormattedHTMLMessage id="app.cancelacion_senator" defaultMessage="FALTA LA TRADUCCION: cancelacion_senator" /></span>
                </div>
            )}
        </>
    );
};

    const showWarningCurrency = props.data.showWarningCurrency;
    
    return (
        <>
            { showWarningCurrency && 
                (
                    <div className="alert alert-warning mt-3 text-body" style={{border:'1px solid #C99900'}}>
                        <div style={{ float: 'left', height: '50px' }}>
                            <img style={{ paddingRight: 10 }} src={require('../../assets/icono-info.svg').default} alt="info icon" />
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            <p style={{ display: 'inline' }}>
                                {intl.messages['app.PagoDivisa'].toString()}
                            </p>
                        </div>
                    </div>
                )
            }

            <span><FormattedHTMLMessage id="app.condiciones_chckbx_aceptar" defaultMessage="Al pagar esta reserva, acepta los" /></span>
            <span className="text-dorado" style={{ paddingTop: 15 }}>
                <a className="text-dorado" href="#" onClick={handleShow}>
                    <FormattedHTMLMessage id="app.terminos" defaultMessage="Términos y condiciones de pago" />
                </a>
            </span>
            <Modal show={show} onHide={handleClose} animation={true} centered={true} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5><FormattedHTMLMessage id="app.condiciones_h5_titulo" defaultMessage="Condiciones" /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { (props.data.cancelacion && props.data.cancelacion.length > 0) ?
                        (
                            <div className="condiciones">
                               {renderCancellationConditions()}
                            </div>
                        )
                        :
                        (
                            <div className="condiciones">
                                {renderTermsConditions()}
                            </div>
                        )
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Condiciones;
