import React, { Dispatch } from "react";
import datosReserva from "../../interfaces/datosReserva";
import PaymentDetails from "../PaymentDetails/PaymentDetails";
import { FormattedHTMLMessage } from "react-intl";
import moment from "moment";
import './PagoResult.css'

type PropsPagoResult = {
    datosReserva: datosReserva,
    typeResult: boolean,
    tryNumber: any,
    setSecondTry: Dispatch<any>
}

const PagoResult = (props: PropsPagoResult) => {

    const {datosReserva, typeResult: resultOK, tryNumber, setSecondTry} = props
    
    return (

        <React.Fragment>
            {
                resultOK && (
                    
                    <div style={{ paddingTop: 20, paddingBottom: '30px' }}>
                        
                        <div className="containerResultOK">
                            <img src={require('../../assets/icono-check.svg').default}/>

                            <div className="successResponse">
                                    <p>Su pago se ha realizado correctamente</p>
                            </div>
                        </div>

                        <PaymentDetails datosReserva={props.datosReserva} />
        
                        <div id="Buttons">
        
                            <a href={process.env.URL_RETURN_WEB}>
                                <FormattedHTMLMessage id="app.ir_sitio_web" defaultMessage="Ir al sitio web" />
                            </a>
                            
                            <a href="#" id="otherPayment" onClick={() => setSecondTry({ 
                                                                                            localizador: datosReserva.localizador, 
                                                                                            fecha:moment.utc(datosReserva.data.reserva.dpentr, 'YYYYMMDD').format("DD/MM/YYYY"),
                                                                                            tryNumber: tryNumber,
                                                                                            paymentWasOK : true
                                                                                        })
                                                                    }>
                                                                        
                                <FormattedHTMLMessage id="app.realizar_otro_pago" defaultMessage="Realizar otro pago" />
                            </a>
        
                        </div>

                    </div>
                )
            }
            {                                                                          
                !resultOK && (
                    
                    <div className="fondoDetalle">

                        <div>
                            <img style={{ paddingRight: 10 }} src={require('../../assets/icono-errorResult.svg').default}/>
                        </div>

                        <div className="errorResponse">
                                <p>Error en el pago</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-2 col-sm-4 hidden-xs text-center pl-3 pt-3 pb-2">
                                <span className="fontBold">
                                    <FormattedHTMLMessage id="app.pago_error_Title" defaultMessage="Lo sentimos pero ha ocurrido un error en el proceso de pago." />
                                </span>
                            </div>
                        </div>

                        <div id="Buttons" className="row" style={{paddingBottom:'25px'}}>

                            <a href="#" id="otherPayment" onClick={() => props.setSecondTry({ 
                                                                                                localizador: datosReserva.localizador, 
                                                                                                fecha:moment.utc(datosReserva.data.reserva.dpentr, 'YYYYMMDD').format("DD/MM/YYYY"),
                                                                                                tryNumber: tryNumber,
                                                                                                paymentWasOK : false
                                                                                            })
                                                                    }>

                                <FormattedHTMLMessage id="app.volver_a_intentarlo" defaultMessage="Volver a intentarlo" />

                            </a>

                        </div>

                    </div>
                )
            }
            
        </React.Fragment>

    )
}

export default PagoResult;

