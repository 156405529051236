import React, { useEffect } from "react";

import '../styles/AppStyles.css';
import { GetOKIcon } from "../utilities/Icons";
import { FormattedHTMLMessage } from "react-intl";

const OKPage = () : JSX.Element => {

    const sendMessage = () : void => {
        window.parent.postMessage("go_home","*");
    }

    useEffect(()=>{
        setTimeout(sendMessage, 10000);
    },[])

    return(
        <div className="ok-page">
            {GetOKIcon()}

            <p className="message ok">
                <FormattedHTMLMessage id="ok.message" defaultMessage="Su pago se ha realizado correctamente"/>
            </p>
            <br/>
            <button onClick={sendMessage} className="btn-go-home btn-continuar">
                <FormattedHTMLMessage id="btn.go-home" defaultMessage="Volver al inicio"/>
            </button>
        </div>
    )
}

export default OKPage;