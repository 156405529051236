import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import datosReserva from "../../interfaces/datosReserva";
import Pago from './Pago';
import RedsysPayment from "./RedsysPayment";

type PropsPayManagement = {
    datosReserva: datosReserva,
    setShowError: Dispatch<SetStateAction<any>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setShowPago: Dispatch<any>,
    setSecondTry: Dispatch<any>,
    setShowWarningCurrency: Dispatch<any>,
    setShowResponse: Dispatch<any>,
    payAllAmount: boolean,
    tryNumber: any,
    TPVName: any
  }

  const PayManagement = (props: PropsPayManagement) => {

      const {datosReserva, setShowError: handleShowError, setLoading, setShowPago, setSecondTry: handleSecondTry, setShowWarningCurrency, setShowResponse, tryNumber, TPVName, payAllAmount} = props;
        
      const getpaycomponent= (tpvname:string):JSX.Element=>{
        let component:JSX.Element=<></>
        switch(tpvname){
            /*case 'Sabadell':
            case 'Santander':
                component= <RedsysPayment datosReserva={datosReserva}
                                        setShowError={handleShowError}
                                        setLoading={setLoading}
                                        setShowPago={setShowPago}
                                        payAllAmount = {payAllAmount}
                                        tpvName = { TPVName }
                            />
            
                break;*/
            default:
                component=<Pago datosReserva={datosReserva}
                setShowError={handleShowError}
                setLoading={setLoading}
                setShowPago={setShowPago}
                setSecondTry = {handleSecondTry}
                tryNumber = {tryNumber ? tryNumber : "1"}
                setShowWarningCurrency = {setShowWarningCurrency}
                setShowResponse = {setShowResponse}
                payAllAmount = {payAllAmount}
                tpvName = { TPVName }/>
            
                break;
            }
            return component;
      }
    
      return (
            <React.Fragment>
                {getpaycomponent(TPVName)}
            </React.Fragment>
      )
  }

  export default PayManagement;