import React, { useEffect, useState } from "react";

import '../styles/AppStyles.css';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Home from "../components/Home";
import Spinner from '../components/Spinner/Spinner';

import datosReserva from '../interfaces/datosReserva';
import PagoResult from "../components/Pago/PagoResult";

import * as signalR from "@microsoft/signalr";


const TPVPage = () : JSX.Element => {

    const search = window.location.search;
    
    const params = new URLSearchParams(search);
    
    const TPVNameParam: string | null = params.get('TPVName');
    const ResultParam: string | null = params.get('Result');
    
    var tpvName = TPVNameParam != null ? TPVNameParam : ''
    var result = ResultParam != null ? ResultParam : 'false'



  const oderIdCustomParam: string | null = params.get('orderIdCustom');

  var orderIdCustom = oderIdCustomParam != null ? oderIdCustomParam : ''

  var thereIsOrderIDCustom = orderIdCustom !== '' ? true: false

  const [callOrderIdCustomInfo, setCallOrderIdCustomInfo]=useState(thereIsOrderIDCustom)
  
  let language: string | null = params.get('lng');
  

  const [locale, setLocale] = useState(language)

    const datosReserva : datosReserva = { 
        code :0 , 
        data :
              {
                hotel : { dphote: 1, hotno : '' }, 
                reserva: { currency: '',  cancelacion:[], customeremail : '', defpag : '', deftop: 1, deimppd : 1, depgdo:'', depositdatemove : '', dpentr: 1, dpfech: '', dpfgrb: 1,
                            dpid : '', dprefe : '', dptitu : '', eN_HOTEL:5, pagO_TRANSFERENCIA: 1, pagado: 11, pendiente: 11, pvp: 10,  restricciones:[], simbol: '#'  }
              }, localizador : '', message: ''
      }
      
    const [showPagoResult, setShowPagoResult] = useState({ showResult : false, resultIsOK : true, datosReserva: datosReserva})

    const handleSecondTry = ({localizador, fecha, tryNumber, paymentWasOK}) => {

        // Si el pago no fue bien y es el segundo intento, entonces pasamos los parámetros
        if (!paymentWasOK && tryNumber == 2){
            window.location.replace( process.env.URL_ANOTHER_PAYMENT + `?localizador=${localizador}&fecha=${fecha}&tryNumber=${tryNumber}&lng=${locale}` );
        }
        else
        {
           window.location.replace( process.env.URL_ANOTHER_PAYMENT + '');
        }
      }

      
  const handleTPVHubMessage = objReceived => {

    if (objReceived.TPVName.toUpperCase() == 'SANTANDER'){

      var resultIsOK = objReceived.Message.toUpperCase() == 'OK' ? true : false

      if (resultIsOK){

        datosReserva.data.hotel.hotno = objReceived.HotelName
        datosReserva.localizador = objReceived.Locator
        datosReserva.data.reserva.currency = objReceived.Currency
        datosReserva.data.reserva.pvp = objReceived.PVP 
        datosReserva.data.reserva.simbol = objReceived.Symbol
        datosReserva.data.reserva.dptitu = objReceived.Titular
  
      }

      setCallOrderIdCustomInfo(false)
      setShowPagoResult({showResult : true, resultIsOK, datosReserva })
    }

  } 

  const fecthGetOrderIDCustomData = async () => {

    const response = await fetch(`${process.env.URL_API_ENDPOINT}/api/TPV/GetOrderIdCustomData?TPVName=${tpvName}&result=${result}&orderIdCustom=${orderIdCustom}`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
   })

   //const {acsURL, md, paReq, termUrl, creq, protocolVersion } = await response.json()
  }

  useEffect(() => {

    const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${process.env.URL_API_ENDPOINT}/TPVhub`)
    .configureLogging(signalR.LogLevel.Information)  
    .build();
    
    hubConnection.start().then(a => {
        
        if (hubConnection.connectionId) {
            hubConnection.on('ReceiveMessage', objTPVHub => {
                handleTPVHubMessage(JSON.parse(objTPVHub))
            })
        }   
    }); 

    if (callOrderIdCustomInfo){
      fecthGetOrderIDCustomData()
    }

  }, []);

    return(
        <div className="tpv-page">
           <Header />

            <div className="container vertical-center">
            {
                !showPagoResult.showResult && !callOrderIdCustomInfo ? <Home setSecondTry = { handleSecondTry } /> :  
                !callOrderIdCustomInfo ?          
                (<PagoResult
                    datosReserva={showPagoResult.datosReserva}
                    typeResult = { showPagoResult.resultIsOK }
                    tryNumber = {1}
                    setSecondTry = {handleSecondTry}
                />)
                : <Spinner text='app.loading' />
            }
            </div>

            <Footer />    
        </div>
    )
}

export default TPVPage;