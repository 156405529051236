import React, { useState } from 'react';
import './App.css';
import { IntlProvider } from "react-intl";
import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";
import { LangContext } from './components/Context/Context';
import RoutesApp from './RoutesApp';

  const App: React.FC = () => {
    const messages: any = {
      'es-ES': messages_es,
      'en-GB': messages_en
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);

  let language: string | null = params.get('lng') ?? 'es-ES';

  const [locale, setLocale] = useState(language)

  language = (language == "en-GB" || language == "es-ES") ? language : "es-ES";


  return (
    <LangContext.Provider value={{locale, setLocale}}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <RoutesApp />
      </IntlProvider>
    </LangContext.Provider>
  );
}


export default App;
